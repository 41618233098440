import React from "react";
import { AVATAR3 } from "../../lib/assets";

export const BlogAuthorInfo2 = () => {
  return (
    <div className="blog-avatar-wrap mb-65">
      <div className="blog-avatar-img">
        <a href="#">
          <img src={AVATAR3} alt="img" />
        </a>
      </div>

      <div className="blog-avatar-info">
        <span className="designation">Author</span>
        <h4 className="name">
          <a href="#">A.M Anfas</a>
        </h4>

        <p>
        SEO is the compass guiding businesses through the vast digital landscape, ensuring they're seen by those seeking their offerings amidst the online cacophony.
        </p>
      </div>
    </div>
  );
};

export default BlogAuthorInfo2;

import React from "react";
import { Link } from "react-router-dom";
import { Odometer } from "../Odometer/Odometer";
import {
  INNER_ABOUT_IMG01,
  INNER_ABOUT_IMG02,
  INNER_ABOUT_SHAPE01,
} from "../../lib/assets";

export const AboutSeven = () => {
  return (
    <section className="about-area-seven pt-120 pb-120 ">
      <div className="container">
        
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9 order-0 order-lg-2">
            <div className="about-img-seven-wrap">
              <img
                src={INNER_ABOUT_IMG01}
                alt="hhh"
                data-aos="fade-right"
                data-aos-delay="0"
              />
              <img
                src={INNER_ABOUT_IMG02}
                alt=""
                data-aos="fade-up"
                data-aos-delay="300"
              />
              <img
                src={INNER_ABOUT_SHAPE01}
                alt=""
                className="shape"
                data-aos="zoom-in"
                data-aos-delay="500"
              />
              <div
                className="experience-wrap"
                data-aos="fade-left"
                data-aos-delay="0"
              >
                <h2 className="title">3+</h2>
                <p>Years Of Experience</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-seven">
              <div className="section-title mb-30">
                <span className="sub-title">Who We are</span>
                <h2 className="title">
                Pioneering IT Strategies for Business Excellence
                </h2>
              </div>
              <p>
              At TechForward Solutions, we're all about using the latest IT strategies to push your business towards excellence. We offer innovative tech solutions and expert advice to ensure lasting success.
              </p>

              <div className="success-wrap-two">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-rating"></i>
                    </div>
                    <div className="content">
                      <h6 className="count">
                        <Odometer end={100} />%
                      </h6>
                      <p>Customer Satisfaction</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-business-presentation"></i>
                    </div>
                    <div className="content">
                      <h6 className="count">
                        <Odometer end={95} />%
                      </h6>
                      <p>Increase in sales</p>
                    </div>
                  </li>
                </ul>
              </div>

              <p className="info-two">
              Our team of experts provides personalized advice, ensuring your business not only meets the current standards but sets new benchmarks for success. With us, your journey towards lasting success is assured.
              </p>

              <Link to="/contact" className="btn btn-three">
                Contact With Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

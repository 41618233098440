import React from "react";
import {
  OVERVIEW_IMG01,
  OVERVIEW_IMG02,
  OVERVIEW_IMG_SHAPE,
} from "../../lib/assets";
import { Odometer } from "../Odometer/Odometer";

export const OverviewAreaOne = () => {
  return (
    <section className="overview-area pt-120 pb-120">
      <div
        className="overview-shape"
        data-aos="fade-left"
        data-aos-delay="200"
      ></div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="overview-img-wrap">
              <img src={OVERVIEW_IMG01} alt="" />
              <img src={OVERVIEW_IMG02} alt="" data-parallax='{"x" : 50 }' />
              <img src={OVERVIEW_IMG_SHAPE} alt="" />
              <div className="icon">
                <i className="flaticon-report-1"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="overview-content">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style3">
                <span className="sub-title">Company Overview</span>
                <h2 className="title tg-element-title">
                  Plan your business strategy with Our Experts
                </h2>
              </div>
              <p className="info-one">
              Unlock the full potential of your business with the guidance and expertise of our seasoned professionals at Synertech Ventures. Our dedicated team is here to collaborate with you every step of the way, offering tailored solutions and strategic insights to help shape and refine your business strategy.
              </p>
              <p className="info-two">
              Whether you're seeking to enter new markets, enhance operational efficiency, or drive innovation, our comprehensive consultation services ensure that your vision is translated into actionable plans for success. Trust Synertech Ventures to be your strategic partner in navigating the ever-evolving landscape of business growth and development.
              </p>
              <div className="content-bottom">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-trophy"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <Odometer end={20} />+
                      </h2>
                      <p>Projects</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-rating"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <Odometer end={25} />
                      </h2>
                      <p>Happy Clients</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

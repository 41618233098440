import React from "react";
import { AVATAR4 } from "../../lib/assets";

export const BlogAuthorInfo3 = () => {
  return (
    <div className="blog-avatar-wrap mb-65">
      <div className="blog-avatar-img">
        <a href="#">
          <img src={AVATAR4} alt="img" />
        </a>
      </div>

      <div className="blog-avatar-info">
        <span className="designation">Author</span>
        <h4 className="name">
          <a href="#">Abdul wahhab</a>
        </h4>

        <p>
        Artificial Intelligence are Empowering humanity to achieve the extraordinary.
        </p>
      </div>
    </div>
  );
};

export default BlogAuthorInfo3;

import React from "react";
import { AVATAR2 } from "../../lib/assets";

export const BlogAuthorInfo1 = () => {
  return (
    <div className="blog-avatar-wrap mb-65">
      <div className="blog-avatar-img">
        <a href="#">
          <img src={AVATAR2} alt="img" />
        </a>
      </div>

      <div className="blog-avatar-info">
        <span className="designation">Author</span>
        <h4 className="name">
          <a href="#">Ashan Gunarathna</a>
        </h4>

        <p>
        Digital marketing: where creativity meets strategy in the ever-evolving online realm.
        </p>
      </div>
    </div>
  );
};

export default BlogAuthorInfo1;

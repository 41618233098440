import React from "react";
import { Layout } from "../../layouts/Layout";
import { CAREER_01 } from "../../lib/assets";

const CareerPage = () => {
  return (
    <Layout breadcrumb={"Careers"} title={"Careers"} header={4}>
      <div className="row">
        <div className="col-12 d-flex justify-content-center align-items-center py-5">
          <img src={CAREER_01} alt="" width={"550px"} />
        </div>
        <div className="col-12 col-md-6 offset-md-3 py-4">
          <p className="text-center fs-5">
            We appreciate your interest! Currently, there are no open vacancies.
            Please check back with us in the future.Thank you!
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default CareerPage;

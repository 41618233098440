import React from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/BlogAreas/BlogPageWrapper";
import { Link } from "react-router-dom";
import {
  BLOG_AVATAR03,
  BLOG_DETAILS01,
  BLOG_DETAILS02,
  CHECK_ICON,
} from "../../lib/assets";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { BlogAuthorInfo } from "../../components/BlogAreas/BlogAuthorInfo";
import { BlogComments } from "../../components/BlogAreas/BlogComments";
import { BlogCommentForm } from "../../components/BlogAreas/BlogCommentForm";
import BlogAuthorInfo2 from "../../components/BlogAreas/BlogAuthorInfo2";

const BlogDetailsPage1 = () => {
  return (
    <Layout breadcrumb={"Blog Details"} title={"Blog Details"} header={4}>
      <BlogPageWrapper>
        {/* image */}
        <div className="blog-details-thumb">
          <img src={BLOG_DETAILS01} alt="" />
        </div>

        {/* content */}
        <div className="blog-details-content">
          <h2 className="title">Maximizing Your Online Presence: A Guide to Effective Search Engine Optimization (SEO)</h2>

          <div className="blog-meta-three">
            <ul className="list-wrap">
              <li>
                <i className="far fa-calendar"></i>15 Feb, 2024
              </li>
              <li>
                <img src={BLOG_AVATAR03} alt="" style={{width:'40px'}}/>
                by <Link to="/blog-details">A.M Anfas</Link>
              </li>
              <li>
                <i className="fas fa-tags"></i>
                <Link to="/blog">Internet,</Link>
                <Link to="/blog">Business</Link>
              </li>
              <li>
                <i className="flaticon-speech-bubble"></i>
                <Link to="/blog-details">05 Comments</Link>
              </li>
            </ul>
          </div>

          <p>
            In today's digital landscape, where consumers turn to search engines like Google to find answers, products, and services, having a strong online presence is crucial for businesses of all sizes. Search Engine Optimization (SEO) plays a pivotal role in ensuring that your website ranks prominently in search engine results, driving organic traffic and increasing visibility to your target audience. In this blog post,
          </p>
          <p>
          we'll explore the fundamentals of SEO, along with strategies, tips, and best practices to help you maximize your online presence and achieve success in the competitive digital marketplace.
          </p>
          <blockquote>
            <p>
              “ SEO isn't just about ranking high in search results; it's about being found by the right audience at the right time, delivering value that resonates and builds lasting connections. ”
            </p>
          </blockquote>
          <h4 className="title-two">Why We Need SEO?</h4>
          <p>
          We need SEO to ensure that our website ranks prominently in search engine results, making it more visible to our target audience. By optimizing our website for search engines, we can increase organic traffic, improve online visibility, and ultimately, drive business growth and success in the digital age.
          </p>

          <div className="bd-inner-wrap">
            <div className="row align-items-center">
              <div className="col-46">
                <div className="thumb">
                  <img src={BLOG_DETAILS02} alt="" />

                  <VideoPlayerOne
                    src={"https://www.youtube.com/watch?v=6mkoGSqTqFI"}
                  />
                </div>
              </div>

              <div className="col-54">
                <div className="content">
                  <h3 className="title-two">
                   Advantage of SEO
                  </h3>
                  <p>
                  SEO drives targeted traffic to your site by optimizing it for relevant keywords, attracting users actively searching for related products or services. This targeted traffic converts into leads or customers, delivering a higher ROI than other channels. SEO also builds credibility, as higher search rankings convey authority. It's a cost-effective way to increase website traffic and grow your business online.
                  </p>
                  <ul className="list-wrap">
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Visibility
                    </li>
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Credibility
                    </li>
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Targeting
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <p>
          SEO is essential for businesses to improve online visibility, credibility, and targeting. By optimizing websites for relevant keywords, businesses attract targeted traffic, increasing conversions and ROI. SEO builds trust and authority, establishing credibility for the brand. As a cost-effective approach, SEO drives growth in the competitive online landscape.
          </p>
          <div className="bd-content-bottom">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="post-tags">
                  <h5 className="title">Tags:</h5>
                  <ul className="list-wrap">
                    <li>
                      <a href="#">Internet</a>
                    </li>
                    <li>
                      <a href="#">Business</a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* bottom */}
              <div className="col-md-5">
                <div className="blog-post-share">
                  <h5 className="title">Share:</h5>
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* auhor info */}
        <BlogAuthorInfo2 />

        {/* comments */}
        {/* <BlogComments /> */}

        {/* comment form */}
        <BlogCommentForm />
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogDetailsPage1;

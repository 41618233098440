import React from "react";
import { AVATAR } from "../../lib/assets";

export const BlogAuthorInfo = () => {
  return (
    <div className="blog-avatar-wrap mb-65">
      <div className="blog-avatar-img">
        <a href="#">
          <img src={AVATAR} alt="img" />
        </a>
      </div>

      <div className="blog-avatar-info">
        <span className="designation">Author</span>
        <h4 className="name">
          <a href="#">Mr. Malinga Fonseka</a>
        </h4>

        <p>
        client trust drives us. They choose our web applications for our proven excellence. As leaders in the field, we anticipate delivering top-tier solutions, surpassing expectations with unmatched results.
        </p>
      </div>
    </div>
  );
};

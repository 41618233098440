import React from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/BlogAreas/BlogPageWrapper";
import { Link } from "react-router-dom";
import {
  BLOG_AVATAR02,
  BLOG_DETAILS01,
  BLOG_DETAILS02,
  CHECK_ICON,
} from "../../lib/assets";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { BlogAuthorInfo } from "../../components/BlogAreas/BlogAuthorInfo";
import { BlogComments } from "../../components/BlogAreas/BlogComments";
import { BlogCommentForm } from "../../components/BlogAreas/BlogCommentForm";
import {BlogAuthorInfo1} from "../../components/BlogAreas/BlogAuthorInfo1";

const BlogDetailsPage1 = () => {
  return (
    <Layout breadcrumb={"Blog Details"} title={"Blog Details"} header={4}>
      <BlogPageWrapper>
        {/* image */}
        <div className="blog-details-thumb">
          <img src={BLOG_DETAILS01} alt="" />
        </div>

        {/* content */}
        <div className="blog-details-content">
          <h2 className="title">Mastering Digital Marketing: Navigating the Ever-Evolving Landscape</h2>

          <div className="blog-meta-three">
            <ul className="list-wrap">
              <li>
                <i className="far fa-calendar"></i>22 Jan, 2023
              </li>
              <li>
                <img src={BLOG_AVATAR02} alt="" style={{width:'40px'}}/>
                by <Link to="/blog-details">Ashan Gunarathna</Link>
              </li>
              <li>
                <i className="fas fa-tags"></i>
                <Link to="/blog">Social Meadia,</Link>
                <Link to="/blog">Business</Link>
              </li>
              <li>
                <i className="flaticon-speech-bubble"></i>
                <Link to="/blog-details">05 Comments</Link>
              </li>
            </ul>
          </div>

          <p>
          In today's interconnected world, where consumers are increasingly turning to digital channels for information, entertainment, and commerce, the importance of digital marketing cannot be overstated. From small startups to multinational corporations, 
          </p>
          <p>
          businesses of all sizes and industries are leveraging digital marketing strategies to reach their target audience, engage with customers, and drive business growth in the competitive online landscape. In this blog post, we'll delve into the world of digital marketing, exploring key strategies, trends, and insights to help businesses succeed in the digital age.
          </p>
          <blockquote>
            <p>
              “ Digital marketing is the art of transforming data into insights, and insights into actions, ultimately driving meaningful connections and measurable results in the digital realm. ”
            </p>
          </blockquote>
          <h4 className="title-two">why we need to market our business digitally?</h4>
          <p>
          We need to market our business digitally to meet our audience where they are - online. In today's digital age, consumers spend a significant amount of time on digital platforms, from social media and search engines to websites and mobile apps. By embracing digital marketing, we can effectively reach and engage our target audience, build brand awareness, drive website traffic, generate leads, and ultimately, grow our business in a competitive online landscape.
          </p>

          <div className="bd-inner-wrap">
            <div className="row align-items-center">
              <div className="col-46">
                <div className="thumb">
                  <img src={BLOG_DETAILS02} alt="" />

                  <VideoPlayerOne
                    src={"https://www.youtube.com/watch?v=6mkoGSqTqFI"}
                  />
                </div>
              </div>

              <div className="col-54">
                <div className="content">
                  <h3 className="title-two">
                  Advantage of Digital Marketing
                  </h3>
                  <p>
                  Measurable results and targeted reach are key advantages of digital marketing. Analytics and data tracking enable real-time measurement of campaign effectiveness, while highly targeted messaging maximizes engagement and conversion rates.
                  </p>
                  <ul className="list-wrap">
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Reach
                    </li>
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Engagement
                    </li>
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Measurability
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <p>
          In summary, digital marketing is a transformative tool for businesses in the modern age. With its expansive reach, engagement potential, and measurable impact, it enables companies to effectively connect with their target audience, drive growth, and enhance brand visibility. As technology evolves, digital marketing remains crucial for businesses to adapt, optimize strategies, and thrive in today's competitive landscape.
          </p>
          <div className="bd-content-bottom">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="post-tags">
                  <h5 className="title">Tags:</h5>
                  <ul className="list-wrap">
                    <li>
                      <a href="#">Social Meadia</a>
                    </li>
                    <li>
                      <a href="#">Business</a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* bottom */}
              <div className="col-md-5">
                <div className="blog-post-share">
                  <h5 className="title">Share:</h5>
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* auhor info */}
        <BlogAuthorInfo1 />

        {/* comments */}
        {/* <BlogComments /> */}

        {/* comment form */}
        <BlogCommentForm />
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogDetailsPage1;

import { RIGHT_ARROW } from "../lib/assets";

export const FEATURE_TWO_LIST = [
  {
    id: 1,
    icon: "flaticon-profit",
    title: "Growing Business",
    subtitle: "Finance transforms software firms into strategic assets.",
  },
  {
    id: 2,
    icon: "flaticon-development",
    title: "IT Consultation service",
    subtitle: "Expert IT consultation to optimize your business.",
  },
  {
    id: 3,
    icon: "flaticon-dashboard",
    title: "Digital Solutions",
    subtitle: "Transforming Businesses & Revolutionizing Industries",
  },
 
];

export const FEATURE_THREE_LIST = [
  {
    id: 1,
    title: "Finance Planning",
    subtitle:
      "Morem Ipsum Dolor Sittemet Consectetur Adipiscing Elitflorai Psum Dolor.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 2,
    title: "Business Consulting",
    subtitle:
      "Morem Ipsum Dolor Sittemet Consectetur Adipiscing Elitflorai Psum Dolor.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 3,
    title: "Tax Advisory",
    subtitle:
      "Morem Ipsum Dolor Sittemet Consectetur Adipiscing Elitflorai Psum Dolor.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 4,
    title: "Business Audit",
    subtitle:
      "Morem Ipsum Dolor Sittemet Consectetur Adipiscing Elitflorai Psum Dolor.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
];

export const FEATURE_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-life-insurance",
    title: "More coverage",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-handshake",
    title: "Less hassle",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    title: "Faster benefits",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-puzzle-piece",
    title: "Powerful Solutions for Your Business",
    subtitle: "Tailored solutions to boost your business.",
  },
  {
    id: 2,
    icon: "flaticon-inspiration",
    title: "Innovative Strategies Driving Success",
    subtitle: "Creative strategies for business success.",
  },
  {
    id: 3,
    icon: "flaticon-profit",
    title: "Catalysts for Business Growth",
    subtitle: "Igniting exponential growth for your business.",
  },
];

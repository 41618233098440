import React from "react";

export const ContactAreaMap = () => {
  return (
    <div className="contact-map">
      <iframe
       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.4371083386613!2d79.9004786757141!3d6.838082219426274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25beba41e5213%3A0xb4f9f131cf980d65!2sSynerTech%20Ventures!5e0!3m2!1sen!2slk!4v1707281994287!5m2!1sen!2slk"
        allowfullscreen
        loading="lazy"
        title="contact map"
      ></iframe>
    </div>
  );
};

import {
  BLOG_AVATAR01,
  BLOG_AVATAR02,
  BLOG_AVATAR03,
  BLOG_AVATAR04,
  BLOG_AVATAR05,
  BLOG_AVATAR06,
  BLOG_IMG01,
  BLOG_IMG02,
  BLOG_IMG03,
  H2_BLOG_IMG01,
  H2_BLOG_IMG02,
  H2_BLOG_IMG03,
  H3_BLOG_IMG01,
  H3_BLOG_IMG02,
  H3_BLOG_IMG03,
  H3_BLOG_IMG04,
  H3_BLOG_IMG05,
  H3_BLOG_IMG06,
  H4_BLOG_IMG01,
  H4_BLOG_IMG02,
  H4_BLOG_IMG03,
  RC_POST01,
  RC_POST02,
  RC_POST03,
  RC_POST04,
} from "../lib/assets";

export const BLOGS_LIST_TWO = [
  {
    id: 1,
    href: "/blog-details",
    src: H2_BLOG_IMG01,
    src2: H3_BLOG_IMG01,
    src3: H4_BLOG_IMG01,
    src4: BLOG_IMG01,
    tag: "Development",
    tag2: "Car Insurance",
    tag3: "Development",
    tagHref: "/blog",
    title: "Digital Marketing",
    subtitle:
      "Test 1",
    avatar: BLOG_AVATAR01,
    author: "Kat Doven",
    author2: "Admin",
    createdAt: "22 Jan, 2023",
  },
  {
    id: 2,
    href: "/blog-details",
    src: H2_BLOG_IMG02,
    src2: H3_BLOG_IMG02,
    src3: H4_BLOG_IMG02,
    src4: BLOG_IMG02,
    tag: "Business",
    tag2: "Home Insurance",
    tag3: "Development",
    tagHref: "/blog",
    title: "Meet AutoManage, the best AI management tools",
    subtitle:
      "Test 2",
    avatar: BLOG_AVATAR01,
    author: "Kat Doven",
    author2: "Admin",
    createdAt: "22 Jan, 2023",
  },
  {
    id: 3,
    href: "/blog-details",
    src: H2_BLOG_IMG03,
    src2: H3_BLOG_IMG03,
    src3: H4_BLOG_IMG03,
    src4: BLOG_IMG03,
    tag: "Tax Advisory",
    tag2: "Family Insurance",
    tag3: "Development",
    tagHref: "/blog",
    title: "Test 3",
    subtitle:
      "Test 3",
    avatar: BLOG_AVATAR01,
    author: "Kat Doven",
    author2: "Admin",
    createdAt: "22 Jan, 2023",
  },
];

export const BLOG_CATEGORIES = [
  {
    label: "Web Applications",
    value: "01",
  },

  {
    label: "Digital Marketing",
    value: "01",
  },

  {
    label: "SEO",
    value: "01",
  },

  {
    label: "Artifical Intelligense (AI)",
    value: "01",
  },
  // {
  //   label: "Business",
  //   value: "02",
  // },
  // {
  //   label: "Consulting",
  //   value: "08",
  // },
  // {
  //   label: "Corporate",
  //   value: "05",
  // },
  // {
  //   label: "Design",
  //   value: "02",
  // },
  // {
  //   label: "Fashion",
  //   value: "11",
  // },
  // {
  //   label: "Marketing",
  //   value: "12",
  // },
];

export const RECENT_BLOGS = [
  {
    id: 1,
    createdAt: "15 Feb, 2024",
    href: "/blog-details",
    src: RC_POST01,
    title: "Unleashing the Power of Web Applications: Transforming Online Experiences",
  },
  {
    id: 2,
    createdAt: "15 Feb, 2024",
    href: "/blog-details",
    src: RC_POST02,
    title: "Mastering Digital Marketing: Navigating the Ever-Evolving Landscape",
  },
  {
    id: 3,
    createdAt: "15 Feb, 2024",
    href: "/blog-details",
    src: RC_POST03,
    title: "Unlocking the Power of Search Engine Optimization (SEO): Strategies for Online Visibility",
  },
  {
    id: 4,
    createdAt: "15 Feb, 2024",
    href: "/blog-details",
    src: RC_POST04,
    title: "Artificial Intelligence (AI): Transforming Industries and Shaping the Future",
  },
];

export const BLOG_TAGS = ["Finance", "Consultancy", "Data", "Agency", "Travel"];

export const BLOG_PAGE_LIST = [
  {
    id: 1,
    createdAt: "15 Feb, 2024",
    title:
      "Unleashing the Power of Web Applications: Transforming Online Experiences",
    subtitle:
      " Navigating the Dynamic Landscape of Web Development and User Engagement",
    tag: "Development",
    author: "Ashan Gunarathna",
    src: H3_BLOG_IMG01,
    authorSrc: BLOG_AVATAR02,
    href: "/blog-details",
    tagHref: "/blog",
  },
  {
    id: 2,
    createdAt: "15 Feb, 2024",
    title:
      "Mastering Digital Marketing: Navigating the Ever-Evolving Landscape",
    subtitle: "Strategies, Trends, and Insights for Success in the Digital Age",
    tag: "Business",
    author: "Ashan Gunarathna",
    src: H3_BLOG_IMG02,
    authorSrc: BLOG_AVATAR02,
    href: "/blog-details1",
    tagHref: "/blog",
  },
  {
    id: 3,
    createdAt: "15 Feb, 2024",
    title: "Unleashing the Potential of SEO: Proven Strategies for Enhanced Online Visibility" ,
    subtitle:
      "Navigating the Complex World of SEO to Boost Your Website's Ranking and Reach",
    tag: "Internet",
    author: "A.M Anfas",
    src: H3_BLOG_IMG03,
    authorSrc: BLOG_AVATAR03,
    href: "/blog-details2",
    tagHref: "/blog",
  },
  {
    id: 4,
    createdAt: "15 Feb, 2024",
    title:
      "Artificial Intelligence (AI): Transforming Industries and Shaping the Future",
    subtitle:
      "Exploring the Impact, Applications, and Potential of AI in Today's World",
    author: "Abdul wahhab",
    tag: "Technology",
    src: H3_BLOG_IMG04,
    authorSrc: BLOG_AVATAR04,
    href: "/blog-details3",
    tagHref: "/blog",
  },
  // {
  //   id: 5,
  //   createdAt: "22 Jan, 2023",
  //   title: "Meet AutoManage, the best AI management tools",
  //   subtitle:
  //     "Test 5",
  //   tag: "Consultancy",
  //   author: "Annette Black",
  //   src: H3_BLOG_IMG05,
  //   authorSrc: BLOG_AVATAR05,
  //   href: "/blog-details",
  //   tagHref: "/blog",
  // },
  // {
  //   id: 6,
  //   createdAt: "22 Jan, 2023",
  //   title: "Meet AutoManage, the best AI management tools",
  //   subtitle:
  //     "Test 6",
  //   tag: "Finance",
  //   author: "Eleanor Pena",
  //   src: H3_BLOG_IMG06,
  //   authorSrc: BLOG_AVATAR06,
  //   href: "/blog-details",
  //   tagHref: "/blog",
  // },
];

import React from "react";
import { Layout } from "../../layouts/Layout";
import { ServiceSix } from "../../components/Services/ServiceSix";

const ServicesPage = () => {
  return (
    <Layout breadcrumb={"Services"}  title={'Our Services'} header={4}>
      {/* services */}
      <ServiceSix />
    </Layout>
  );
};

export default ServicesPage;

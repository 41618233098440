import React from "react";
import { Layout } from "../../layouts/Layout";
import { BlogPageWrapper } from "../../components/BlogAreas/BlogPageWrapper";
import { Link } from "react-router-dom";
import {
  AVATAR4,
  BLOG_DETAILS01,
  BLOG_DETAILS02,
  CHECK_ICON,
} from "../../lib/assets";
import { VideoPlayerOne } from "../../components/VideoPlayers/VideoPlayerOne";
import { BlogAuthorInfo } from "../../components/BlogAreas/BlogAuthorInfo";
import { BlogComments } from "../../components/BlogAreas/BlogComments";
import { BlogCommentForm } from "../../components/BlogAreas/BlogCommentForm";
import BlogAuthorInfo3 from "../../components/BlogAreas/BlogAuthorInfo3";

const BlogDetailsPage1 = () => {
  return (
    <Layout breadcrumb={"Blog Details"} title={"Blog Details"} header={4}>
      <BlogPageWrapper>
        {/* image */}
        <div className="blog-details-thumb">
          <img src={BLOG_DETAILS01} alt="" />
        </div>

        {/* content */}
        <div className="blog-details-content">
          <h2 className="title">Artificial Intelligence (AI): Transforming Industries and Shaping the Future</h2>

          <div className="blog-meta-three">
            <ul className="list-wrap">
              <li>
                <i className="far fa-calendar"></i>15 Feb, 2024
              </li>
              <li>
                <img src={AVATAR4} alt="" style={{width:'40px'}}/>
                by <Link to="/blog-details">Abdul wahhab</Link>
              </li>
              <li>
                <i className="fas fa-tags"></i>
                <Link to="/blog">AI,</Link>
                <Link to="/blog">Modern technologies</Link>
              </li>
              <li>
                <i className="flaticon-speech-bubble"></i>
                <Link to="/blog-details">00 Comments</Link>
              </li>
            </ul>
          </div>

          <p>
          In recent years, Artificial Intelligence (AI) has emerged as a transformative force reshaping industries, revolutionizing processes, and shaping the future of technology. From intelligent virtual assistants and autonomous vehicles to advanced data analytics and personalized recommendations, 
          </p>
          <p>
          AI is permeating every aspect of our lives, driving innovation and unlocking new possibilities. In this blog post, we'll delve into the world of Artificial Intelligence, exploring its impact, applications, and the potential to revolutionize the way we live, work, and interact in the digital age.
          </p>
          <blockquote>
            <p>
              “ Artificial Intelligence is not just about machines thinking like humans; it's about machines empowering humans to think beyond limits. ”
            </p>
          </blockquote>
          <h4 className="title-two">Why we need to use AI</h4>
          <p>
          We use AI to unlock new possibilities, streamline processes, and solve complex problems in a rapidly evolving digital landscape. AI empowers businesses to make data-driven decisions, personalize customer experiences, automate tasks, and drive innovation. By harnessing AI, we enhance efficiency, drive growth, and stay competitive.
          </p>

          <div className="bd-inner-wrap">
            <div className="row align-items-center">
              <div className="col-46">
                <div className="thumb">
                  <img src={BLOG_DETAILS02} alt="" />

                  <VideoPlayerOne
                    src={"https://www.youtube.com/watch?v=6mkoGSqTqFI"}
                  />
                </div>
              </div>

              <div className="col-54">
                <div className="content">
                  <h3 className="title-two">
                   Advantage of AI
                  </h3>
                  <p>
                  AI offers increased efficiency through automation, data-driven decision-making, and enhanced productivity. It also improves customer experiences with personalized recommendations and efficient customer service interactions. Overall, AI is invaluable for businesses in the digital age.
                  </p>
                  <ul className="list-wrap">
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Efficiency
                    </li>
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Automation
                    </li>
                    <li>
                      <img src={CHECK_ICON} alt="" />
                      Personalization
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <p>
          AI revolutionizes industries and enhances capabilities. Despite challenges, its benefits in automation, data-driven decision-making, and personalized experiences are undeniable. Embracing AI responsibly ensures progress with ethical standards, promising a future where technology enriches lives and fosters positive impact.
          </p>
          <div className="bd-content-bottom">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="post-tags">
                  <h5 className="title">Tags:</h5>
                  <ul className="list-wrap">
                    <li>
                      <a href="#">AI</a>
                    </li>
                    <li>
                      <a href="#">Modern Technology</a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* bottom */}
              <div className="col-md-5">
                <div className="blog-post-share">
                  <h5 className="title">Share:</h5>
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* auhor info */}
        <BlogAuthorInfo3 />

        {/* comments */}
        {/* <BlogComments /> */}

        {/* comment form */}
        <BlogCommentForm />
      </BlogPageWrapper>
    </Layout>
  );
};

export default BlogDetailsPage1;
